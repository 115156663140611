import React from 'react';

const Contact = () => {
    return (
        <div className="container mt-5">
            <h4 className="text-center mb-4">Kontakt</h4>
            <p>
                ZSE IMMOBILIEN GmbH
                <br/>
                Telefon: +49 6184 95 65 200
                <br/>
                E-Mail: info@zse-immobilien.de
                <br/>
                Ladenstraße 6, 63517 Rodenbach (bei Hanau)
                <br/>
                <br/>
                <br/>
            </p>
        </div>
    );
};

export default Contact;
