import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../style/navbar.css';


const Navbar = () => {
  const navigate = useNavigate();
  
  // Überprüfe, ob der Benutzer eingeloggt ist (Token vorhanden)
  const isLoggedIn = !!localStorage.getItem('authToken');

  const handleLogout = () => {
    // Entferne den Token und leite zur Login-Seite weiter
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <Link to="/" className="navbar-brand">Konzeptvergabe.de</Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ms-auto">
                      <li className="nav-item">
                          <Link to="/" className="nav-link">Home</Link>
                      </li>
                      {!isLoggedIn ? (
                          <>
                              <li className="nav-item">
                                  <Link to="/login" className="nav-link">Login</Link>
                              </li>
                              <li className="nav-item">
                                  <Link to="/register" className="nav-link">Registrieren</Link>
                              </li>
                          </>
                      ) : (
                          <>
                              <li className="nav-item">
                                  <Link to="/list" className="nav-link">Inserate</Link>
                              </li>
                              <li className="nav-item">
                                  <Link to="/dashboard" className="nav-link">Dashboard</Link>
                              </li>
                              <li className="nav-item">
                                  <a className="nav-link" onClick={handleLogout}>Logout</a>
                              </li>
                          </>
                      )}
                  </ul>
              </div>
          </div>
      </nav>
  );
};

export default Navbar;
