import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showRequestLink, setShowRequestLink] = useState(false); // Neues State für den Link
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();

    const userCredentials = {
      email,
      password
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, userCredentials);

      // Token im localStorage speichern
      localStorage.setItem('authToken', res.data.token);

      // Zur Dashboard-Seite navigieren
      navigate('/dashboard');

    } catch (err) {
      // Setze die Fehlermeldung basierend auf der Serverantwort
      if (err.response && err.response.data.message) {
        if (err.response.data.message.includes('E-Mail wurde noch nicht bestätigt')) {
          setErrorMessage(err.response.data.message);
          setShowRequestLink(true); // Zeige den Link an, wenn der Benutzer nicht bestätigt wurde
        } else {
          setErrorMessage(err.response.data.message);
          setShowRequestLink(false); // Keine Linkanzeige für andere Fehler
        }
      } else {
        setErrorMessage('An unexpected error occurred');
        setShowRequestLink(false);
      }
    }

    setPassword('');
  };

  const handleRequestConfirmation = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/user/request-confirmation`, { email });
      setErrorMessage('Ein neuer Bestätigungslink wurde gesendet. Bitte überprüfen Sie Ihre E-Mails.');
      setShowRequestLink(false); // Link ausblenden nach dem Anfordern
    } catch (err) {
      setErrorMessage('Fehler beim Anfordern des Bestätigungslinks. Bitte versuchen Sie es erneut.');
    }
  };

  return (
      <div className="container main-content mt-5">
        <h2 className="text-center mb-4">Login</h2>

        {/* Fehlernachricht anzeigen, falls vorhanden */}
        {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
              {showRequestLink && (
                  <>
                    &nbsp;<a href="#" onClick={handleRequestConfirmation}>Neuen Bestätigungslink anfordern</a>
                  </>
              )}
            </div>
        )}

        <form onSubmit={onSubmit}>
        <div className="form-group">
            <label>E-Mail: </label>
            <input
                type="email"
                required
                className="form-control"
                value={email}
                onChange={onChangeEmail}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Passwort: </label>
            <input
                type="password"
                required
                className="form-control"
                value={password}
                onChange={onChangePassword}
            />
          </div>
          <br />
          <div className="form-group">
            <input type="submit" value="Login" className="btn btn-primary" />
          </div>
        </form>
      </div>
  );
};

export default LoginUser;
