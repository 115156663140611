import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root');


const ArealPreview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [areal, setAreal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentField, setCurrentField] = useState(''); // Aktuelles Feld, das bearbeitet wird
    const [fieldValue, setFieldValue] = useState(''); // Aktueller Wert des bearbeiteten Feldes
    const [modalTitle, setModalTitle] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false); // Lightbox Zustand
    const [modalType, setModalType] = useState('');

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        const fetchAreal = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/preview/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setAreal(response.data);
                setLoading(false);
            } catch (error) {
                setError("Areal konnte nicht gefunden werden");
                setLoading(false);
            }
        };
        fetchAreal();
    }, [id]);

    const openModal = (field, title, type) => {
        setModalTitle(title + " bearbeiten")
        setModalType(type);
        setCurrentField(field);
        setFieldValue(areal[field]);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalTitle('')
        setModalType('');
        setCurrentField('');
        setFieldValue('');
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.put(`${process.env.REACT_APP_API_URL}/areal/${id}`, {
                field: currentField,
                value: fieldValue
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAreal({ ...areal, [currentField]: fieldValue }); // Wert im Frontend aktualisieren
            closeModal();
        } catch (error) {
            console.error('Fehler beim Speichern:', error);
        }
    };

    const toggleStatus = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const newStatus = areal.status === 1 ? 0 : 1;
            await axios.put(`${process.env.REACT_APP_API_URL}/areal/${id}`, {
                field: 'status',
                value: newStatus
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAreal({ ...areal, status: newStatus }); // Status im Frontend aktualisieren
        } catch (error) {
            console.error('Fehler beim Statuswechsel:', error);
        }
    };


    if (loading) {
        return <div className="container mt-5 text-center"><h2>Laden...</h2></div>;
    }

    if (error) {
        return <div className="container mt-5"><p>{error}</p></div>;
    }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-8">
                    <div className="card mb-4">
                        <img src="https://via.placeholder.com/800x400" className="card-img-top" alt="Areal Bild"/>
                        <div className="card-body">
                            <div className="row">
                                {/* Linke Spalte */}
                                <div className="col-md-6">
                            <div className="title-container card-text">
                                <h5 className="card-title">{areal.name}</h5>
                                &nbsp;<a className="greenlink" onClick={() => openModal('name', 'Name', 'text')}>Bearbeiten</a>
                            </div>
                            <p className="card-text">
                                <strong>Nutzung:</strong> {areal.usage}
                                &nbsp;<a className="greenlink"
                                         onClick={() => openModal('usage', 'Nutzung', 'text')}>Bearbeiten</a>
                            </p>
                            <p className="card-text">
                                <strong>Kommentar:</strong> {areal.comment}
                                &nbsp;<a className="greenlink"
                                         onClick={() => openModal('comment', 'Kommentar', 'text')}>Bearbeiten</a>
                            </p>
                            <p className="card-text">
                                <strong>Baurecht:</strong> {areal.law}
                                &nbsp;<a className="greenlink"
                                         onClick={() => openModal('law', 'Baurecht', 'text')}>Bearbeiten</a>
                            </p>
                            <p className="card-text">
                                <strong>Baurecht Kommentar:</strong> {areal.lawcomment}
                                &nbsp;<a className="greenlink"
                                         onClick={() => openModal('lawcomment', 'Baurecht Kommentar', 'text')}>Bearbeiten</a>
                            </p>
                            <p className="card-text">
                                <strong>Mindestpreis:</strong> {areal.minprice} €
                                &nbsp;<a className="greenlink"
                                         onClick={() => openModal('minprice', 'Mindestpreis', 'number')}>Bearbeiten</a>
                            </p>
                            <p className="card-text">
                                {areal.status === 1 ? (
                                    <a className="status-link text-success" onClick={() => toggleStatus()}>
                                        <i className="fas fa-eye"></i> Veröffentlicht
                                    </a>
                                ) : (
                                    <a className="status-link text-warning" onClick={() => toggleStatus()}>
                                        <i className="fas fa-eye-slash"></i> Privat
                                    </a>
                                )}
                            </p>
                                </div>
                                <div className="col-md-6">
                                    <p className="card-text">
                                        <strong>Beschreibung:</strong> {areal.description}
                                        &nbsp;<a className="greenlink"
                                                 onClick={() => openModal('description', 'Beschreibung')}>Bearbeiten</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
                <h6>{modalTitle}</h6>
                <input
                    className="modal-input"
                    type={modalType}
                    value={fieldValue}
                    onChange={(e) => setFieldValue(e.target.value)}
                />
                <div className="modal-buttons">
                    <button onClick={handleSave} className="btn btn-success">Speichern</button>
                    &nbsp;
                    <button onClick={closeModal} className="btn btn-secondary">Abbrechen</button>
                </div>
            </Modal>

            <div className="mt-5 text-center">
                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Zurück zur Übersicht</button>
            </div>
        </div>
    );
};

export default ArealPreview;
