import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';

import Navbar from "./components/navbar.component";
import Footer from "./components/footer.component";
import Home from "./components/home.component";
import RegisterUser from "./components/register-user.component";
import LoginUser from "./components/login.component";
import Dashboard from './components/dashboard.component';
import ArealList from "./components/areal-list.component";
import ArealPage from "./components/areal-page.component";
import ArealPreview from "./components/areal-preview.component";
import CreateAreal from "./components/areal-create.component";
import ProtectedRoute from "./components/protected-route.component";
import ConfirmationPage from "./components/mail-confirmation.component";
import Imprint from "./components/imprint.component";
import Privacy from "./components/privacy.component";
import Contact from "./components/contact.component";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<LoginUser />} />
        <Route path="/list" element={<ArealList />} />
        <Route path="/register" element={<RegisterUser />} />
        <Route path="/create" element={<CreateAreal />} />
        <Route path="/confirm/:token" element={<ConfirmationPage />} />

        <Route path="/dashboard" element={
                  <ProtectedRoute>
                      <Dashboard />
                  </ProtectedRoute>}
        />
        <Route path="/preview/:id" element={
            <ProtectedRoute>
                <ArealPreview />
            </ProtectedRoute>}
        />
        <Route path="/areal/:id" element={<ArealPage />} />
        <Route path="/impressum" element={<Imprint />} />
        <Route path="/datenschutz" element={<Privacy />} />
        <Route path="/kontakt" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
