import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ArealPage = () => {
    const { id } = useParams(); // ID des Areals aus der URL entnehmen
    const navigate = useNavigate();
    const [areal, setAreal] = useState(null); // Areal-Daten speichern
    const [loading, setLoading] = useState(true); // Ladezustand anzeigen
    const [error, setError] = useState(null); // Fehlerzustand speichern

    useEffect(() => {
        const fetchAreal = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/object/${id}`);
                setAreal(response.data); // Areal-Daten setzen
                setLoading(false); // Ladezustand beenden
            } catch (error) {
                console.error("Fehler beim Laden des Areals:", error);
                setError("Areal konnte nicht gefunden werden");
                setLoading(false); // Ladezustand beenden
            }
        };
        fetchAreal();
    }, [id]);

    if (loading) {
        return <>
            <div className="container mt-5 text-center">
                <h2>Laden...</h2>
            </div>
        </>;
    }

    if (error) {
        return <>
            <div className="container mt-5">
                <p>{error}</p>
            </div>
        </>;
    }

    return (
        <div className="container mt-5">
        <div className="row">
                <div className="col-md-8">
                    <div className="card mb-4">
                        <img
                            src="https://via.placeholder.com/800x400"
                            className="card-img-top"
                            alt="Areal Bild"
                        />
                        <div className="card-body">
                            <div className="row">
                                {/* Linke Spalte */}
                                <div className="col-md-6">
                                    <h3 className="card-title">{areal.name}</h3>
                                    <p className="card-text">
                                        <strong>Nutzung:</strong> {areal.usage}
                                    </p>
                                    <p className="card-text">
                                        <strong>Kommentar:</strong> {areal.comment}
                                    </p>
                                    <p className="card-text">
                                        <strong>Baurecht:</strong> {areal.law}
                                    </p>
                                    <p className="card-text">
                                        <strong>Baurecht Kommentar:</strong> {areal.lawcomment}
                                    </p>
                                    <p className="card-text">
                                        <strong>Mindestpreis:</strong> {areal.minprice} €
                                    </p>
                                </div>
                                {/* Rechte Spalte */}
                                <div className="col-md-6">
                                    <p className="card-text">
                                        <strong>Beschreibung:</strong> {areal.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            <div className="col-md-4">
                <div className="card">
                    <div className="card-header">
                        <h5>Kontakt & Informationen</h5>
                    </div>
                    <div className="card-body">
                        <p><strong>Inseriert von:</strong> Anbieter Name</p>
                        <p><strong>Kontaktdaten:</strong> Beispiel E-Mail / Telefonnummer</p>
                        <button className="btn btn-primary btn-block">
                            Kontaktieren
                        </button>
                    </div>
                </div>
            </div>
        </div>

            <div className="mt-5 text-center">
                <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                    Zurück zur Übersicht
                </button>
            </div>
        </div>
    );
};

export default ArealPage;
