import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Entferne die geschweiften Klammern bei jwtDecode

const Home = () => {
    const navigate = useNavigate();
   // const [userrole, setUserrole] = useState("Nicht angemeldet");
    const [areals, setAreals] = useState([]);

    useEffect(() => {
       /* const fetchUserrole = () => {
            const token = localStorage.getItem('authToken');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    setUserrole(decodedToken.userrole || "Nicht angemeldet");
                } catch (error) {
                    console.error("Fehler beim Dekodieren des Tokens:", error);
                    setUserrole("Nicht angemeldet");
                }
            }
        };*/

        const fetchAreals = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/home`);
                setAreals(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        //fetchUserrole();
        fetchAreals();
    }, []);

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Willkommen bei Konzeptvergabe.de!</h2>
            <p className="text-center"><h5>Wir sind bald soweit!</h5><br/><br/>Bewerben Sie als Kommune Ihre Grundstücksangebote.
                <br/>
                <br/>
                Finden Sie als Bewerber das passende Grundstück und verwalten Sie Ihre Angebote.
            </p>

            <div className="row">
                {areals.length > 0 ? (
                    areals.map((areal) => (
                        <div className="col-md-4" key={areal._id}>
                            <div className="card mb-4">
                                <img
                                    src="https://via.placeholder.com/350x200" // Platzhalterbild
                                    className="card-img-top"
                                    alt="Projekt"
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{areal.name}</h5>
                                    <p className="card-text">{areal.comment}</p>
                                    {/* Link zur Detailseite des Areals */}
                                    <a href={`/areal/${areal._id}`} className="greenlink">Mehr erfahren</a>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (<></>
                    //<p className="text-center">Keine Inserate verfügbar.</p>
                )}
            </div>

            <div className="text-center">
                <button className="btn btn-success mt-4" onClick={() => navigate('/register')}>Jetzt registrieren und bewerben</button>
            </div>
        </div>
    );
};

export default Home;
