import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const Dashboard = () => {
    const navigate = useNavigate();
    const [userrole, setUserrole] = useState('');
    const [username, setUsername] = useState('');
    const [areals, setAreals] = useState([]);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        const fetchUserrole = () => {
            const token = localStorage.getItem('authToken');
            try {
                const decodedToken = jwtDecode(token);
                setUserrole(decodedToken.userrole);
                fetchAreals();
            } catch (error) {
                console.error('Ungültiges Token:', error);
                localStorage.removeItem('authToken');
                navigate('/login');
            }
        };
        const fetchUsername = () => {
            const token = localStorage.getItem('authToken');
            try {
                const decodedToken = jwtDecode(token);
                setUsername(decodedToken.username);
            } catch (error) {
                console.error('Ungültiges Token:', error);
                localStorage.removeItem('authToken');
                navigate('/login');
            }
        };

        const fetchAreals = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/dashboard`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setAreals(response.data);
            } catch (error) {
                const errorMessage = error.response.data.message
                if(!errorMessage)
                {
                    setError("Unbekannter Fehler");
                }
                else
                {
                    setError(errorMessage);
                }
            }
        };

        fetchUserrole();
        fetchUsername();
    }, [navigate]);

    const toggleStatus = async (arealId, currentStatus) => {
        try {
            const token = localStorage.getItem('authToken');
            const newStatus = currentStatus === 1 ? 0 : 1;  // Status wechseln (öffentlich <-> privat)
            await axios.put(`${process.env.REACT_APP_API_URL}/areal/${arealId}`,
                {
                    field: 'status',
                    value: newStatus
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

            setAreals(areals.map(areal =>
                areal._id === arealId ? { ...areal, status: newStatus } : areal
            ));
        } catch (error) {
            console.error('Fehler beim Ändern des Status:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Dashboard</h2>
            {/* Fehlermeldung anzeigen */}
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}

            {/* Erfolgsmeldung anzeigen */}
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            <div className="row">
                <div className="col-6">
                    <p>Hallo {username}!</p>
                </div>
                {userrole ==='Anbieter' && (<div className="col-6">
                    <a href="#"
                       className="greenlink float-end"
                       onClick={() => navigate(`/create`)}>
                        Neues Inserat
                    </a>
                </div>)}
            </div>

            {userrole === 'Anbieter' ? (
                <div className="row">
                    {areals.length > 0 ? (
                        areals.map((areal) => (
                            <div className="col-md-4" key={areal._id}>
                            <div className="card mb-4">
                                <img
                                    src="https://via.placeholder.com/350x200" // Platzhalterbild
                                    className="card-img-top"
                                    alt="Areal"
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{areal.name}</h5>
                                    <p className="card-text">{areal.comment}</p>
                                    <p className="card-text">
                                        {/* Status-Button zum Wechseln */}
                                        {areal.status === 1 ? (
                                            <a className="status-link text-success"
                                               onClick={() => toggleStatus(areal._id, areal.status)}>
                                                <i className="fas fa-eye"></i> Veröffentlicht
                                            </a>
                                        ) : (
                                            <a className="status-link text-warning"
                                               onClick={() => toggleStatus(areal._id, areal.status)}>
                                                <i className="fas fa-eye-slash"></i> Privat
                                            </a>
                                        )}
                                    </p>
                                    <a
                                        className="greenlink"
                                        onClick={() => navigate(`/preview/${areal._id}`)}>
                                        Vorschau und bearbeiten
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center">Keine Inserate verfügbar.</p>
                )}
            </div>
            ) : (
                <p className="text-center"><i>Bieter Dashboard folgt...</i></p>
            )}

        </div>
    );
};

export default Dashboard;
