import React from 'react';

const Privacy = () => {
    return (
        <div className="container mt-5">
            <h4 className="text-center mb-4">Datenschutz</h4>
            <p>
                Name des Unternehmens: ZSE Immobilien GmbH
                <br/>
                <br/>
                Name des Datenschutzbeauftragten: Fabian Trollmann
                <br/>
                <br/>
                Datenschutzerklärung
                <br/>
                <br/>
                Diese Datenschutzerklärung soll die Nutzer unserer Website über die Art, den Umfang und den Zweck der
                Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber ZSE Immobilien GmbH
                informieren.
                <br/>
                <br/>
                Wir nehmen Ihren Datenschutz sehr ernst und behandeln Ihre personenbezogenen Daten vertraulich und
                entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
                Weiterentwicklung dieser Website Änderungen an dieser Datenschutzerklärung vorgenommen werden können,
                empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
                <br/>
                <br/>
                Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in
                Art. 4 DSGVO.
                <br/>
                <br/>
                Der Besuch unseres Online-Angebots kann grundsätzlich ohne Offenlegung persönlicher Angaben über Ihre
                Identität wie z.B. Ihren Namen, Ihre E-Mail-Adresse, Adressdaten oder andere Angaben über Ihre Person
                erfolgen.
                <br/>
                <br/>
                Um unser Internetangebot besser auf Ihre Bedürfnisse anpassen zu können, speichern wir mit dem Zugriff
                auf unsere Internetseiten Ihre um die letzte Ziffer verkürzte IP-Adresse mit Angabe des Datums, der
                Uhrzeit und der betrachteten Seite für zwölf Monate. Die Daten werden außerdem für statistische Zwecke
                ausgewertet. Durch die Verkürzung der IP-Adresse ist anhand der gespeicherten Daten ein Bezug zu Ihrer
                Person nicht möglich.
                <br/>
                <br/>
                Sofern Sie sich für eine Kontaktaufnahme entscheiden, benötigen wir hierzu Angaben zu Ihrer Person wie
                z.B. Ihre E-Mail-Adresse oder Postanschrift. Die Angabe Ihrer personenbezogenen Daten erfolgt
                freiwillig. Wir werden nur die Daten erheben und verarbeiten, die für die Erfüllung des jeweiligen
                Zwecks erforderlich sind. Nach Zweckerfüllung bzw. auf Ihren Wunsch hin werden wir Ihre
                personenbezogenen Angaben löschen, sofern zur Aufbewahrung keine gesetzliche Verpflichtung – z.B. aus
                steuerrechtlichen Gründen – besteht.
                <br/>
                <br/>
                Die Verarbeitung personenbezogener Daten, wie beispielsweise Name, Anschrift, E-Mail-Adresse oder
                Telefonnummer einer betroffenen Person, erfolgt im Einklang mit der Datenschutz-Grundverordnung.
                <br/>
                <br/>
                Die Speicherung Ihrer Daten erfolgt ausschließlich auf besonders geschützten Rechnersystemen in
                Deutschland. Ein Zugriff auf diese Systeme bzw. die darauf gespeicherten Daten ist nur Personen erlaubt,
                die im Rahmen ihrer originären betrieblichen Aufgaben mit der Verwendung der Daten betraut sind und für
                die eine gesonderte passwortgeschützte Zugriffsberechtigung eingerichtet ist. Mit Hilfe angemessener
                technischer und organisatorischer Maßnahmen zum Datenschutz und zur IT-Sicherheit gewährleisten wir,
                dass Ihre Angaben stets vertraulich behandelt werden. Dennoch können internetbasierte Datenübertragungen
                grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann.
                Aus diesem Grund steht es jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen
                Wegen, beispielsweise telefonisch, an uns zu übermitteln. Eine Weitergabe Ihrer personenbezogenen Daten
                an andere Stellen als die an der Bearbeitung Ihres Anliegens beteiligten erfolgt nicht.
                <br/>
                <br/>
                Weitergabe personenbezogener Daten an Dritte
                <br/>
                <br/>
                Angaben zu Ihrer Person werden nur firmenintern erhoben, gespeichert oder verarbeitet sowie durch
                externe Dienstleister, die im Rahmen der geltenden Datenschutzvorschriften als Auftragsdatenverarbeiter
                vertraglich gebunden sind.
                <br/>
                <br/>
                Soweit eine gesetzliche Verpflichtung besteht, unser Unternehmen per Gerichtsbeschluss dazu verpflichtet
                ist, sowie zu Zwecken der Strafverfolgung, werden wir Ihre Daten an auskunftsberechtigte Stellen
                übermitteln.
                <br/>
                <br/>
                Verlinkung zu anderen Internetseiten
                <br/>
                <br/>
                Trotz sorgfältiger Kontrolle wird für Inhalte externer Links keine Haftung übernommen. Für den Inhalt
                verlinkter Seiten sind ausschließlich deren Betreiber verantwortlich. Bei der Linklegung war kein
                Hinweis dafür erkennbar gewesen, dass die Inhalte der aufzurufenden Seite nicht den gesetzlichen
                Bestimmungen entsprochen hat. Wir bitten Sie um sofortige Benachrichtigung, wenn eine verlinkte Seite
                nicht oder nicht mehr den gesetzlichen Bestimmungen entspricht. Es gelten die Lizenz- und
                Nutzungsbedingungen der jeweiligen Betreiber des Internetangebots.
                <br/>
                <br/>
                Rechte des Nutzers
                <br/>
                <br/>
                Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche
                personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung
                falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls
                zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass
                Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen
                Aufsichtsbehörde einreichen.
                <br/>
                <br/>
                Löschung von Daten
                <br/>
                <br/>
                Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B.
                Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns
                gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine
                gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann,
                da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der
                Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.
                <br/>
                <br/>
                Widerspruchsrecht
                <br/>
                <br/>
                Nutzer dieser Website können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer
                personenbezogenen Daten zu jeder Zeit widersprechen.
                <br/>
                <br/>
                Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten
                personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer
                personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an
                folgende E-Mail-Adresse: ftrollmann@konzeptvergabe.de
            </p>
        </div>
    );
};

export default Privacy;
