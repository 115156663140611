import React from 'react';

const Imprint = () => {
    return (
        <div className="container mt-5">
            <h4 className="text-center mb-4">Impressum</h4>
            <p>ZSE IMMOBILIEN GmbH
                <br/>
                Telefon: +49 6184 95 65 200
                <br/>
                E-Mail: info@zse-immobilien.de
                <br/>
                Geschäftsführung:
                Dipl.-Ing. Ralf Trollmann
                <br/>
                <br/>
                Sitz der Gesellschaft:
                <br/>
                Ladenstraße 6, 63517 Rodenbach (bei Hanau)
                <br/>
                Registergericht: Amtsgericht Hanau HRB 95509
                <br/>
                Ust.Id.Nr.: DE304437353
                <br/>
                Redaktion: Fabian Trollmann
                <br/>
                Verbraucherinformationen Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission
                stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Zur Plattform</p>
        </div>
    );
};

export default Imprint;
