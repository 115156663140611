import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const CreateAreal = () => {
    const [name, setName] = useState('');
    const [usage, setUsage] = useState('');
    const [comment, setComment] = useState('');
    const [law, setLaw] = useState('');
    const [lawcomment, setLawcomment] = useState('');
    const [minprice, setMinprice] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [userrole, setUserrole] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserRole = () => {
            const token = localStorage.getItem('authToken');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                   // setUserrole(decodedToken.userrole);
                    if (decodedToken.userrole !== 'Anbieter') {
                        navigate('/login'); // Weiterleitung zur Login-Seite
                    }
                } catch (error) {
                    console.error('Ungültiges Token:', error);
                    localStorage.removeItem('authToken');
                    navigate('/login');
                }
            } else {
                navigate('/login'); // Kein Token, also zur Login-Seite weiterleiten
            }
        };

        checkUserRole();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage(null);
        if (name.length < 3) {
            setError('Der Name muss mindestens 3 Zeichen lang sein.');
            return; // Abbrechen, falls die Bedingung nicht erfüllt ist
        }
        setLoading(true);
        try {
            const token = localStorage.getItem('authToken');
            const decodedToken = jwtDecode(token);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/areal`, {
                name,
                usage,
                comment,
                law,
                lawcomment,
                minprice,
                description,
                userId: decodedToken.id // Benutzer-ID übergeben
            }, {
                headers: {
                    'Authorization': `Bearer ${token}` // Authentifizierung
                }
            });
            setSuccessMessage('Areal erfolgreich erstellt!');
        //    navigate('/dashboard'); // Weiterleitung zum Dashboard nach erfolgreichem Erstellen
        } catch (err) {
            setError(err.response ? err.response.data.message : 'Serverfehler');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Neues Areal erstellen</h2>
            {/* Fehlermeldung anzeigen */}
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}

            {/* Erfolgsmeldung anzeigen */}
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="usage">Nutzung</label>
                    <input
                        type="text"
                        id="usage"
                        className="form-control"
                        value={usage}
                        onChange={(e) => setUsage(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="comment">Kommentar</label>
                    <textarea
                        id="comment"
                        className="form-control"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="law">Gesetz</label>
                    <input
                        type="text"
                        id="law"
                        className="form-control"
                        value={law}
                        onChange={(e) => setLaw(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lawcomment">Gesetz Kommentar</label>
                    <textarea
                        id="lawcomment"
                        className="form-control"
                        value={lawcomment}
                        onChange={(e) => setLawcomment(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="minprice">Mindestpreis</label>
                    <input
                        type="number"
                        id="minprice"
                        className="form-control"
                        value={minprice}
                        onChange={(e) => setMinprice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Beschreibung</label>
                    <textarea
                        id="description"
                        className="form-control"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Speichern...' : 'Erstellen'}
                </button>
            </form>
        </div>
    );
};

export default CreateAreal;
